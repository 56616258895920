	/*@media (max-width: 97vh) {
	.nav-collapse {
    	overflow: auto;
	}
}*/

.hmenu{
   max-height: calc(100vh - 100px);
   overflow-y: auto;
}

@media (max-width: 992px) {
	.navbar-nav{
		max-height: calc(100vh - 100px);
   	overflow-y: auto;   	
	}
	.downMenu {
		height: '57px';
	}
}

@media handheld {
	.navbar-nav{
		max-height: calc(100vh - 100px);
   	overflow-y: auto;
	}	
}

.dropdown-menu .dropdown-menu {
	top: auto;
	/*left: 100%;*/
	transform: translateY(-0.7rem);
	/*border-style: solid;
	border: 1px solid #ddd;
	border-radius: 5px;*/
}

.dropdown-item + .dropdown-menu {
	display: none;
}

/*.dropdown-item.submenu::after {
	content: '▸';
	//content: url("../img/2.png");
	margin-left: 0.5rem;
}
.dropdown-item.submenu:hover:after {
	transform: rotate(30deg);
}*/

.dropdown-item:hover + .dropdown-menu,
.dropdown-menu:hover {
	display: block;
}

/*.dropdown-item.submenu:hover::after {

	content: url("37.png");
	margin-left: 0.5rem;

}*/

