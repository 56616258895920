.template-header {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    white-space: break-spaces;
}

.template-h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.main-template{
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #313942; 
      
}

.common-template{   
    display: block; 
    margin-bottom: 8%; 
    margin-top: 20px;
}

.head-template{
    display: flex;
    color: #a0a0a0;
}

.body-template{
    margin-top: 25px;
    width: fit-content;
    display: flex;
    background-color: #fff;
    border-radius: 20px;
    padding: 25px;
    overflow: auto;  
    height: 90vh;
}   

.leftBlock {
    text-align: left;
    padding-right: 60px;
}

.rightBlock {
    text-align: right;
    justify-content: right;
    padding-left: 60px;
}

.topBlock {
    padding-bottom: 60px;
}

.bottomBlock {
    padding-top: 60px;
}

.lastBlock {
    padding-top: 60px;
    padding-bottom: 30px;
}

.left-align {
    text-align: left;
    justify-content: left;
}

.right-align {
    text-align: right;
    justify-content: right;
}

.centerBlock {
    text-align: center; 
    padding-top: 50px;
    padding-bottom: 50px;
}

.flex {
    display: flex;
}

.bill-main {
    min-width: 600px;
}

.line {
    border-bottom: 1px solid #a0a0a0;
}

.movies-table {
    text-align: right;
}

.movies-header {
    color:#a0a0a0 ;
}

.btn-print {
    margin-left: 20px;
}

@media print {
    .mainPrint {        
        background-color: unset;
        overflow:visible;    
    }    
    .commonPrint {        
        width: 100%;
        height: 100%;
        overflow:visible;    
    }
    .noPrint { display: none; }
    .bodyPrint {        
        width: 100%;
        height: 100%;  
        overflow:visible;      
    } 
} 