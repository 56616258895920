.colTitle:hover { cursor:pointer; }

.tf-page {
  /*height: calc(100vh - 60px);*/
}

.tf_box {
  /*max-height: calc(100vh - 140px);*/
  /*height: 50vh;
  width: 100vh;*/
  overflow-x: auto;
  overflow-y: auto;
}

/*th:first-child, td:first-child
{
  position:sticky;
  left:0px;
  background-color:grey;
}*/

.detail-btn {
  color: blue;
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
}

.readOnlyCell-css{
  background-color: #e6fff7;
}

.fixCol_td {
  background-color: #cccccc;
  border: 1px solid white;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #9ec9f3;
}

.table-bordered th {
  border: 1px solid #9ec9f3;
}

.tr {
  line-height: 18px;
}

.table-danger, .table-danger > th, .table-danger > td {
  background-color: #6aa0cf;
  color: #deeaf8;
}

.cell-for-input {
  display: flex; height: 28px; flex-direction: row;
}

.cell-for-input-vertical {
  display: flex; height: 28px; flex-direction: row;
}

.cell-input {
  height: 28px; padding-left: 2px; padding-right: 2px;
}