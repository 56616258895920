.default-btn-block{
  right: 10%;
}

.screen-body{
  overflow-y: auto;
}

.screen-all-fields{
  display: flex;
  flex-wrap: wrap; 
}

.screen-all-fields-vertical{  
}

.screen-vertical{
  width: fit-content;
  min-width: 300px;
}

.label-vertical{
  width: 180px;
  min-width: 180px;
  /* white-space: nowrap; */
  /* overflow: hidden; */
}

.date-field{
  width: 160px;
}

.time-field{
  width: 100px;
}

.timesec-field{
  width: 120px;
}

.screen-content{
  max-height: 80vh;
}

.field{
  min-width: 70px;
  /* max-width: 225px; */
}
.screen-back{
  width: 100%;
  height: 100%;
  position: fixed;
}

.screen-block{
  background-color: #00000078;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: center;
}

.screen-btn{
  margin: 10px;
  min-width: 101px;
}

.screen-textarea{
  resize: both;
}

.cell-for-textarea{
  min-width: 150px;
}

.input-block{
  margin: 10px;
}

.input-block-vertical{
  display: flex;
  margin: 10px;
}

/* .input-field{

}

.input-field-vertical{

} */

.catalog-btn{
  height: 38px;
  width: 38px;
}

.tf-mark-row{
  background-color: aliceblue;
}

.field-warning{
  background-color: yellow;
}

.field-forbidden{
  background-color: red;
}