.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

[unselectable=on] {
  -moz-user-select: none;
  -o-user-select:none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body {
  width: 100%; height: 100%;
  overflow: hidden;
  margin: 0; padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.sticky {
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 5;
}

.sticky {
  display: flex;
  /*align-items: center;*/
  background-color: white;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
}

.col-title {
  background-color: #b8daff;
}

.col-title-danger {
  background-color: #6aa0cf;
  color: #deeaf8;
}

.td-fixed-full {
  position: sticky;
  z-index: 1
}

.btn-toolbar .btn {
  height: 34px;
}

.toolbar .btn {
  width: 38px;
}

.fullsize {
  position: absolute;
  left: 0; top: 0;
  width: 100%; height: 100%;
}

.hide-all {
  background-color: #cccccc99;
}

.autorization {
  height: 100vh;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.setting {
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.e_detail_btn:hover { cursor:pointer; }

.page-setting {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* .cell-for-textarea > textarea:focus, .cell-for-input > input:focus{
  outline: none;
  border-color: none;
  -webkit-box-shadow: none;
  box-shadow: none;
} */

.GridInner {
  position: relative;
}

.tf-row {
  position: absolute;
  display: inline-flex;
  width: 100%;
  height: 28px;
}

.tf-title-row {
  left: 0;
  width: 100%;
}

.tf-cell {
  top: 0;
  overflow: hidden;
  padding: 5px;
  padding-top: 5px;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  /*display: 'flex',  justifyContent: 'center',*/
  /*cursor: rowIndex > 0 ? '' : 'pointer',*/
  /*position: absolute;
  z-index: 0;*/
}

.tf-title-cell {
  top: 0;
  border-right: 2px solid #9ec9f3;
  border-bottom: 2px solid #9ec9f3;
  overflow: hidden;
  font-weight: bold;
  padding: 5px;
  line-height: 16px;
  cursor: pointer;
}

.tf-title-cell-sticky {
  position: sticky;
  z-index: 5;
}

.tf-title-cell-absolute {
  position: absolute;
  z-index: 0;
}

.tf-left-cell {
  left: 0;
  top: 0;
  border-right: 2px solid #9ec9f3;
  border-bottom: 2px solid #9ec9f3;
  height: 28px;
  overflow: hidden;
  padding: 5px;
  line-height: 20px;
  cursor: pointer;
  position: sticky;
  z-index: 3;
}

.tf-title-left-cell {
  top: 0;
  left: 0;
  border-right: 2px solid #9ec9f3;
  border-bottom: 2px solid #9ec9f3;
  overflow: hidden;
  font-weight: bold;
  padding: 5px;
  line-height: 16px;
  cursor: pointer;
  position: sticky;
  z-index: 5;
}

.cell-input, .cell-for-input * {
  outline: none;
  border: none;
  border-color: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.minw80vw {
  min-width: 80vw;
}

.tf-title-text {
  margin-bottom: 0;
  margin-top: 0.2rem;
}

.tf-filter-field {
  margin-left: auto;
}

.tf-container {
  display: flex;
  flex-direction: row;
}

.tf-container > .tf-size-field {
  flex: 1;
  /* float: right;
  width: 200px;
  height: 200px; */
}

@media print {
  body, #root {
    overflow: visible;        
  } 
}

.tf-container > .right-panel-block {
  flex: 1;
}

.right-panel-block > .modal-content {
  border: none;
}