.kanban-header {
    display: flex;
    justify-content: space-around;
}

.kanban-body {
    display: flex;
    height: calc(100vh - 140px);
    overflow: auto;    
    background-color: whitesmoke;
}

.kanban-footer{
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;    
    bottom: 20px;
    right: 5px;
}

.kanban-filter-block {
    display: flex;
}

.kanban-filter {
    margin-left: 20px;
}

.kanban-column-header {
    height: 66px;
    width: 280px;
    min-width: 280px;
    max-width: 280px;
    padding-top: 20px;   
    margin-left: auto;
    margin-right: auto;
}

.name-header {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 12px;  
    font-family: Arial Black;
    width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.kanban-line {
    height: 2px;
    background-color: #99ccff;
    color: #99ccff;
    width: 280px;
}

.column-drop-place {
    background-color: gray;
}

.sticker-sum{
    text-align: center;
    font-size: 12px;
    width: 280px;
}

.stickers-block {
    /* height: calc(100% - 66px); */
    overflow: auto;  
    min-width: 320px;
    max-width: 337px;  
}

.sticker-body, .sticker-new-place {    
    background-color: white;
    width: 280px;
    min-width: 280px;
    max-width: 280px;
    min-height: 80px;    
    margin: 20px;
    border-radius: 10px;
    cursor:grab;
}

.sticker-new-place {
    background-color: gray;
    opacity: 0.3;
    box-shadow: inset 0px 5px 5px black;
}

.sticker-id, .sticker-date-input{
    font-size: 10pt;
}

.sticker-id, .sticker-date, .sticker-date-input{    
    padding-top: 4px;
    color: grey;    
    width: 50%;    
}

.sticker-name, .sticker-date{
    cursor: text;
}

.sticker-right-bottom, .sticker-left-bottom{    
    padding-bottom: 4px;
    color: grey;    
    width: 50%;  
}

.sticker-any-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sticker-id, .sticker-left-bottom {
    padding-left: 8px;
}

.sticker-date, .sticker-right-bottom {
    padding-right: 8px;
}

.sticker-name {    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
    font-size: 12pt;  
    font-family: Arial Black;
    text-align: center;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.sticker-date, .sticker-date-input, .sticker-right-bottom{
    text-align: end;   
}


.sticker-dragging {    
    opacity: 0.4;
}

.mouse-over {
    box-shadow: 0px 0px 16px 0px black;
}

.kanban-btns-TFGroup {
    display: flex;
    margin-left: 20px;
}

.kanban-btn-TF {
    display: flex;
    margin-left: 5px;
    align-items: center;
}



.left-footer{
    display: flex;
    margin-left: 21px;
}

.right-footer{
    display: flex;   
}

.footer-block{    
    margin-right: 12px;
    margin-bottom: 6px;
    text-align: center;    
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 12px;
    font-family: Arial Black;  
    height: 60px;  
}

.trash-icon{
    display: flex;
    justify-content: center;
    font-size: 24px;
    padding: 10px;
    color: red;
    pointer-events: none;
}

.trash-line, .cancel-line, .finish-line, .not_start-line{   
    height: 2px;
    pointer-events: none;
}

.cancel-line, .finish-line, .not_start-line{    
    width: 280px;
}

.trash-line {
    background-color: red;
    width: 100px;
}

.cancel-line {
    background-color: gray;
}

.finish-line{
    background-color: green;    
}

.not_start-line{
    background-color: gray;    
}

.not_start-word, .cancel-word, .finish-word{
    display: flex;
    height: 100%;    
    align-items: center;
    justify-content: center;
    color: black;
    pointer-events: none;
}

.flex-space {
    display: flex;
    justify-content: space-between;
}



.kanban-shadow {
    box-shadow: 2px 5px 5px black
}

.kanban-modal-content {
    position: relative;    
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.status-div {
    border-radius: 50%;
    margin-left: 4px;
    margin-top: 8px;
    height: 15px;
    width: 15px;
}
